// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyA8ZV4YIoPE0TQXiQwdxOW8Qn_ySCm9YJk",
  authDomain: "yuze-family-fish.firebaseapp.com",
  projectId: "yuze-family-fish",
  storageBucket: "yuze-family-fish.appspot.com",
  messagingSenderId: "580753525470",
  appId: "1:580753525470:web:00ab73e1a60cdb66e9d04f",
  measurementId: "G-QMX49MGFDE"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
